* {
  margin: 0;
  padding: 0;
}
.con{
  background-image: url(/public/images/img-4.jpg);
  margin-top: -52px;
  background: cover;
  height: 852px;
}
.con1 {
  width: 100%;
  height: 750px;
 
}
.from-control {
  margin-bottom: 15px;
  width: 90%;
}

.card{
  margin-top: 100px;
  box-shadow: 0px 0px 30px black;
  opacity: 0.95;
  /* filter: brightness(130%); */
}

#form1 {
  height: 450px;
  flex-direction: column;
  display: flex;
  margin: 30px 0 0 100px;
  line-height: 3;
  background-color: rgb(240, 235, 229);
}
textarea {
  width: 90%;
  text-indent: 22px;
}
.head{
  margin-top: 40px;
}

#btn {
  width: 20%;
  margin-top: 30px;
 
  display: flex;
  margin-left: 35%;
  border-radius: 30px;
  color: beige;
  border: 4px solid white;
  /* background-color:rgb(17, 17, 122); */
  background-color: rgb(5, 166, 246);
  font-size: 22px;
  font-weight: 600;
  text-indent: 4px;
}

#btn:hover{
  color: rgb(17, 17, 17);
  background-color: aquamarine;
}
.container24 {
  display: flex;
  height: 800px;
}

.container24 h1 {
  color: azure;
  /* margin-left: 100px;
  margin-top: 350px; */
      margin-left: 56px;
        margin-top: 241px;
}

input {
  text-indent: 22px;
}
.card {
  display: flex;
  background-color: rgb(240, 235, 229);
  width: 70%;
 border-radius: 20px;
  margin-top: 176px;
    margin-right: 100px;
}

@media screen and (max-width: 992px) {
  .container24 h1 {
    margin-left: 40px;
    margin-top: 20px;
  }
    .container24 {
      margin-top: 105px;
      display: block;
    }

  #form1 {
    height: 450px;
    flex-direction: column;
    display: flex;
    margin: 30px 0 0 37px;
    line-height: 3;
  }

  #btn {
    width: 108px;
    margin-left: 18%;
        text-indent: 18px;
  }
  .card {
    margin-left: 63px;
  }
}
