.img1_text{
    color: white;
    font-size: 50px;
     margin : 500px;
     margin-bottom: 700px;

}
.image1{

    height: 80vh;
}
.content{
    margin-top: 100px;
    margin-bottom: 800px;
}
.head{
    color: white;
}
.larger-text{
    font-size: 25px;
    font-weight: 400;
}
.demo{
    border-color: brown;
    box-shadow: 0 0 30px;
   
    margin-left: 150px;
    background-color: black;
    margin-bottom: 20px;
    opacity: 0.8;
} 

@media screen and (max-size) {

    
    
}

