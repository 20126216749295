.demo{
    border-color: brown;
    box-shadow: 0 0 30px;
    margin-left: 150px;
    margin-right: 150px;
    background-color: black;
    margin-bottom: 20px;
    opacity: 0.8;
}
.custom-heading {
    color: white; /* Replace with your desired color */
    font-weight: 900;
}
.para{
    color: white;
}


@media screen and (max-width: 992px) {
.demo {
    border-color: brown;
    box-shadow: 0 0 30px;
    margin-left: 10px;
    /* margin-right: 150px; */
    background-color: black;
    /* margin-bottom: 20px; */
    opacity: 0.8;
    margin-top: -123px;
    width: 93%;
}

}