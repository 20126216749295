.build {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  background-color: white;
  padding: 50px;
}
.build1 {
  margin-top: 180px;
  /* background-color: blueviolet; */
  height: 500px;
  width: 600px;
}
.build1 h3 {
  font-weight: 600;
  color: rgb(7, 7, 100);
}
.build1 p {
  margin-top: 50px;
  font-size: 20px;
  font-weight: 600;
}

.build2 {
  width: 700px;
  margin-top: 100px;
}
@media screen and (max-width: 992px) {
  .build{
    
    height: 100vh;
    background-color: white;
   

  }
    .build2 img {
       
    /* width: 319px;
    height: 240px;
    margin-top: -102px;
    /* margin-right: 102px; */
    /* margin-left: -211px; */ 
    display: none;

  }
 

.build1 h3 {
    font-weight: 600;
    color: rgb(7, 7, 100);
    margin-top: -173px;
    width: 306px;
    margin-left: 2px;
    /* height: 215px; */
}

.buliddes{
width: 306px;

}
}
