
.d-block{
    height: 600px ;
}
.d-none{
    background-color: black;
    opacity: 0.9;
}

.carousel-caption h2{
    /* opacity: 1; */
color: white;
}

.carousel-caption p{
    color: rgb(208, 255, 0);
    font-size: 20px;
    /* opacity:1; */
}

