* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}

.con124 {
    width: 100%;
    height: 2049px;
    background-color: rgb(25, 44, 65);
    
}


.info{
width: 100%;
    height: 750px;
    margin-left: 100px;
    
    
}
.info1{
font-size: 25px;
    width: 120%;
    height: 467px;
/* background-image: url(/public/images/img-4.jpg );  */
     margin-left: 483px;
}

.info1 img {
    margin-left:30px;
    margin-top: 60px;
}

.In p{
    margin-right: 3000px;
}


.info1 p{
    width: 120%;
         height: 467px;
    margin-top: 50px;
    font-weight: 600;
    font-size: 18px;
    color: white; 
     width: 400px; 
        margin-right: 700px; 
 

}
.info h1{
    color:white;
    margin-bottom: 40px;
}

.info12{
width: 400px;
    height: 450px;
    color:white;
    margin-top: 130px;
    box-shadow: 0 0 30px;
    border-radius: 20px;
    padding: 20px;
    font-size: 18px;
    margin-left: -41px;
}

.info12 img{
    margin-left: 430px;
    margin-top: 50px;
    width: 400px;
    height: 400px;
   
}

.In1{
    margin-top: -408px;
} 
.container43{
    width: 100%;
    height: 1349px;
    /* background-image: url(/public/images/img-1.jpeg); */
  
    background-repeat: no-repeat;
    background-size: cover;
 margin-top: 50px;
}


.About{
/* background-image: url(/public/images/img-4.jpg ); */
/* border-radius: 50%; */
    font-size: 18px;
    width: 120%;
        height: 467px;
        color: white;
       padding: 10px;
    
}

.About p{
   
    width: 400px;
    height: 450px;
    padding: 20px;
    margin-left: 430px;
    box-shadow: 0 0 30px;
    border-radius: 20px;
    margin-top: -430px;
}
.About img{
     width: 400px;
     height: 400px;
     margin-left: -31px;
     /* border-radius: 0 0 40%; */
}
.info{
color: white;
font-weight: 600;
    font-size: 18px;
    margin-left: 150px;
    height: 600px;
    width: 50vh;
  
}
.info-1{
    margin-top: 55px;
}

.info2 {
   
    margin-top: -426px;
    margin-left: 392px;
    color:white;
    font-weight: 600;
    font-size: 18px;
   
    box-shadow: 0 0 30px;
    border-radius: 20px;
    width: 400px;
        height: 480px;
}
.info2 p{
    padding: 10px;
}

.botoom{
width: 100%;
    height: 455px;
    margin-top: 150px;
}
.bot1 img{
    /* border-radius: 50%; */
    margin-top: 80px;
    width: 400px;
        height: 400px;
        margin-left: -55px;

}

@media screen and (max-width: 992px) {
    
.con124{
   
        height: 2199px;
    
}
.About img {
    /* width: 150px;
    height: 150px; */
    display: none;
}
.info12 img {
    display: none;
}

.In1 {
    margin-top: -4px;
}
.bot1 img {
    display: none;
}
.info2 {
   
    margin-top: -74px;
    
    color: white;
   
    
    height: 505px;
    box-shadow: 0 0 30px;
    border-radius: 20px;
}
.About p {
    width: 255px;
    height: 562px;
    padding: 20px;
    box-shadow: 0 0 30px;
    border-radius: 20px;
    margin-left: 0px;
    margin-top: 10px;
}
.About{
    color: white;
}
.info12 {
    width: 127%;
    height: 680px;
    color: white;
    /* margin-top: 0px; */
    box-shadow: 0 0 30px;
    border-radius: 20px;
margin-left: 12px;
}
.container43{
    height: 1231px;
    width: 100%;
}
    
    .info {
        color: #dde3ea;
            font-weight: 600;
            font-size: 18px;
            width: 270px;
                margin-left: 40px;
    }
    
      .info-1{
        color: darkseagreen;
        padding-top: 70px ;
        width: 330px;

      }
    .info1{
        margin-left: 0;
        font-weight: 600;
            font-size: 18px;
            width: 270px;
    }
    .info2{
        margin-left: 9px;
            font-weight: 600;
            font-size: 18px;
            width: 345px;
    }
}

@media screen and (max-width: 820px) {

    .About p {
            border-radius: 20px;
                margin-left: 0px;
                margin-top: 10px;
                    width: 400px;
                        height: 450px;
                        padding: 20px;
    }
    .info12{
        width: 400px;
            height: 450px;
    }
    .info2{
        width: 400px;
            height: 450px;
    }
}

@media screen and (max-width: 912px) {

    .About p {
        border-radius: 20px;
        margin-left: 0px;
        margin-top: 10px;
        width: 400px;
        height: 450px;
        padding: 20px;
    }

    .info12 {
        width: 352px;
            height: 450px;
            margin-left: 12px;
    }

    .info2 {
        width: 400px;
        height: 450px;
    }
}

@media screen and (max-width: 280px) {


    .About{
        width: 100%;
    }
    .info{
        width: 239px;
    }
    
        .About p {
                width: 214px;
                height: 729px;
            }
    

    .info12 {
        width: 210px;
            height: 850px;
                margin-top: 445px;
                margin-left: 12px;
    }

    .info2 {
        width: 210px;
            height: 850px;
    }

    .info-1{
            padding-top: 70px;
                width: 220px;
    }
        .con124 {
            height: 3000px;
        }
}


@media screen and (max-width: 412px) {
    .About p{
        width: 341px;
    }
    
          .info12 {
            width: 341px;
            margin-left: 12px;
          }
                  .info2 {
                    width: 341px;
                  }
}

           @media screen and (max-width: 1024px) {

            .About p {
                    width: 350px;
                    height: 465px;
                }
                .info2{
                        width: 350px;
                            height: 465px;
                }

                                .info12 img {
                                    margin-left: 434px;
                                }
                                .info12{
                                    width: 350px;
                                        height: 465px;
                                }
           }


                        @media screen and (max-width: 375px) {

                            .About p {
                                    width: 310px;
                                    height: 480px;
                                }

                                                                .info12 {
                                                                    width: 317px;
                                                                    margin-left: 12px;
                                                                }
.info2 {
    width: 310px;
}
                        }

                                                 @media screen and (max-width: 414px) {
                    .info12 {
                        margin-left: 12px;
                            width: 356px;}
                                                        .info2 {
                                                            width: 356px;
                                                        }
                                                 }


   @media screen and (max-width: 390px) {
    .info12 {
            width: 327px;
            margin-left: 12px;
        }
    
        .info2 {
            width: 327px;
        }
        .About p{
            width: 327px;
            height: 480px;
        }
   }

         @media screen and (max-width: 393px) {
             .info12 {
                 width: 305px;
                margin-left: 12px;
             }
    
             .info2 {
                 width: 305px;
             }
    
             .About p {
                 width: 305px;
                 height: 480px;
             }
         }


                 @media screen and (max-width: 360px) {
                     .info12 {
                        width: 271px;
                            height: 540px;
                            margin-left: 12px;
                     }
        
                     .info2 {
                        width: 271px;
                            height: 540px;
                     }
        
                     .About p {
                        
                                width: 271px;
                                height: 540px;
                     }
                 }
                   
                                 @media screen and (max-width: 280px)  {
                                    
                                    .About p{
                                    width: 212px;
                                   height: 732px;
                                    }
                                    .info12{
                                        width: 212px;
                                            height: 732px;
                                    }

                                    .info2{
                                        width: 212px;
                                            height: 732px;
                                    }
                                  
                                 }
           @media screen and (max-width: 320px) {
            .About p {
                    width: 252px;
                    height: 567px;
                }
                .info12{
                    width: 252px;
                        height: 567px;
                }
                .info2{
                width: 252px;
                    height: 567px;
                }
           }
                                                                