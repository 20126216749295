video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  .main_tag{
    display: inline-block !important;
     transition: opacity 0.5s ease 0s !important; 
     opacity: 1 !important;
  }
  
  .hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 70px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  

 
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }

    .titleTwo {
    animation-name: moveLeft;
    animation-duration: 1s;
    width: 80%;
    margin-left: 60px;
}
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  .text{
    color: #fff;
    font-size: 30px;
  }

.title{
    animation-name:movieRight;
    animation-duration:0.7s ;
}
.titleTwo{
    animation-name:moveLeft;
    animation-duration:1s;
}
.titleThree{
    animation-name:moveRight;
    animation-duration:1.3s;
     margin-left: 10px;
}
@keyframes moveRight{
    0%{
        opacity: 0;
        transform:translateX(50px);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
}
@keyframes moveLeft{
    0%{
        opacity: 0;
        transform:translateX(-150px);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
}
@keyframes moveUp{
    0%{
        opacity: 0;
        transform: translate(50px);
    }
    100%{
        opacity: 1;
        transform: translate(0);
    }
}
@media screen and (max-width: 992px) {
  .Typewriter{
    margin-left: 33px;
  }
}


