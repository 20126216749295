* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.info13 {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
      /* margin-left: 130px;
        margin-top: -80px; */
}
.info-14 {
  margin-top: 90px;
  color: #050300;
  font-size: 50px;
  font-weight: 700;
}

.con11 {
  width: 100%;
  height: 2200px;
  margin-bottom: 300px;
}


.privacy{
    margin-top: 80px;
    margin-right: 300px;
    margin-left: 150px;
    font-size: 18px;
    
}
.Privacy-title{
    font-weight: 700px;
 
}



.container12 {
  height: 750px;
  background-image: url(/public/images/img-2.jpg);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  /* margin-top: 100px; */
  background-size: cover;
  /* margin-top: 50px; */
}
.info-14 {
  /* margin-top: 55px; */
  height: 400px;
  width: 400px;
  background-color: rgb(233, 237, 244);
  color: aliceblue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}
.info-14 h1 {
  color: #050300;
  font-weight: 700;
  font-size: 50px;
}




@media screen and (max-width: 992px) {
  .info-148 h1 {
      color: white;
      font-weight: 700;
      /* margin-right: -10px; */
      font-size: 50px;
  }

  .con11{
      height: 3224px;
  }
      .privacy {
         
          margin-left: 40px;
         margin-top: 80px;
         margin-right: 20px;
      }
}
