
.bg-ground{
  background: linear-gradient(300deg,#FFF,  #2b06ce, #0583ea,#FFF);
  width: 100%;
}
.tit1{
  color: #fff;
  text-align: center;
  padding-top: 50px;
  font-weight: 700;
}
.tit2{
  color: #fff;
  text-align: center;
  padding-top: 30px;
  font-weight: 600;
}

/* .cong {
    height: 100vh;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    font-family: "Raleway", Arial, sans-serif;
    background: #7f9ead;
    position: absolute;
    margin-left: 40px;
    flex-direction: column;
    display: flex;
margin-left: 363px;
height: 692px;
width: 100%;
margin-top: 170px;

  } */
  
  .card112 {
    position: relative;
    width: 412px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    padding: 30px 50px;
    background: #fff;
    cursor: pointer;
    /* margin-right: 30px; */
    margin-left: 250px;
    margin-top: 150px;
    margin-bottom: 150px;
  }
  
  .card112:hover {
    height: 350px;
  }
  
  .imgbox1 {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateY(-80px);
    z-index: 99;
  }
  
  img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  }
  
  .content11 {
    padding: 10px 20px;
    text-align: center;
    transform: translateY(-273px);
    opacity: 0;
    transition: 0.3s;
    font-size: 16px;
    font-weight: 500;
  }
  
  .card112:hover > .content11 {
    opacity: 1;
    transform: translateY(-110px);
  }
  
  .head1 {
    margin-top: 10px;
    color: #020647;
    text-align: center;
    font-weight: 700;
  }


  /* new */


  .card113 {
    position: relative;
    width: 412px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    padding: 30px 50px;
    background: #fff;
    cursor: pointer;
    /* margin-right: 30px; */
    margin-left: 250px;
    margin-top: 144px;
    margin-bottom: 150px;
  }

  .card113:hover {
    height: 350px;
  }

  .card113:hover > .content11 {
    opacity: 1;
    transform: translateY(-110px);
  }


  .card114 {
    position: relative;
    width: 412px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    padding: 30px 50px;
    background: #fff;
    cursor: pointer;
    /* margin-right: 30px; */
    margin-left: 850px;
    margin-top: -455px;
    /* margin-bottom: 150px; */
  }

   .card112:hover {
    height: 350px;
  }

   .card114:hover > .content112 {
    opacity: 1;
    transform: translateY(-110px);
  }

  .content112 {
    padding: 50px 20px;
    text-align: center;
    transform: translateY(-450px);
    opacity: 0;
    transition: 0.3s;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30;
  }

   .card115 {
    position: relative;
    width: 412px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    padding: 30px 50px;
    background: #fff;
    cursor: pointer;
    /* margin-right: 30px; */
    margin-left: 850px;
    margin-top: -455px;
    /* margin-bottom: 150px; */
  }

   .content116 {
    padding: 0px 20px;
    text-align: center;
    transform: translateY(-450px);
    opacity: 0;
    transition: 0.3s;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30;
  }

   .card115:hover {
    height: 350px;
  }

  .card115:hover > .content116 {
    opacity: 1;
    transform: translateY(-110px);
  }

   .imgbox2 {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateY(-80px);
    z-index: 99;
  }


  @media screen and (max-width: 1270px) {

  .card114{
    margin-left: 570px;
  }
  .card112{
    margin-left: 150px;
  }
  .card113{
    margin-left: 150px;
  }

  .card115{
    margin-left: 570px;
  }

  }


  @media screen and (max-width: 992px) {

   .card112 {
    
    width: 37%;
    cursor: pointer;
    margin-left: 36px
}

.card114 {
     
    padding: 30px 50px;
    cursor: pointer;
    margin-left: 500px;
    width: 37%;
}


.card113 {
    margin-left: 36px;
    width: 37%;
}

.card115 {
   
       padding: 30px 40px;

   width: 37%;
    cursor: pointer;
    margin-left: 500px;
}

.content116 {
    margin-top: -25px;
    font-size: 13px;
}

/* .bg-ground {
    background: linear-gradient(300deg,#FFF, #2b06ce, #0583ea,#FFF);
    height: 1300px;
} */

 .content11 {
    margin-top: -25px;
     font-size: 13px;
  }

  .content112 {
    
    margin-top: -34px;
     font-size: 13px;
}
  }

   @media screen and (max-width: 870px) {

.content116 {
    margin-top: -80px;
    
}

.content11 {
    margin-top: -80px;
     
  }

  .content112 {
    
    margin-top: -80px;
     
}

      .imgbox1{
        width: 60%;
    margin-left: 20%;
      }
      .imgbox2{
        width: 60%;
    margin-left: 20%;
      }

      /* .card115:hover {
    height: 362px;
} */



.card112{
  margin-left:80px;
}

.card114{
      margin-left: 422px;
      
}

.card113{
  margin-left:80px ;
   
}

.card115{
  margin-left: 422px;
      
}
     }


     @media screen and (max-width: 679px) {
    
    .card112{
  margin-left:60px;
  padding: 12px;
}
    
    
      .card114 {
    margin-left: 303px;
    padding: 12px;
}

.card113 {
    margin-left: 60px;
    padding: 12px;
}

.card115 {
    margin-left: 303px;
    padding: 12px;
}
     }


  @media screen and (max-width: 482px) {
.imgbox1 {
   
    height: 75%;
   
}
.imgbox2 {
   height: 75%;
   
   
}

.card115{
  height: 375px;
}

.card115:hover {
    height: 395px;
}

.content11{

margin-top: 10px;

}

.card114 {
    margin-left: 240px;

  }

  .card112 {
    margin-left: 32px;

  }
  .card115 {
    margin-left: 240px;

  }

  .card113 {
    margin-left: 32px;

  }

  .content11{
    margin-top: -80px;
  }

}


    @media screen and (max-width: 421px) {



      .bg-ground{
            height: 2179px;
      }
      .imgbox1 {
   
    height: 63%;
   
}
img {
    width: 117%;}


.imgbox2 {
   height: 61%;
   
   
}

.card114{
  
    margin-left: 36px;
    margin-top: 40px;
    width: 84%;
}
.card112{
      height: 330px;
    margin-left: 36px;
    
    width: 84%;
}
.card115{
 height: 347px;
    
    margin-left: 36px;
    width: 84%;
    margin-top: 0px;
}
.card113{
  height: 350px;
  
    margin-left: 36px;
    width: 84%;
}

/* .content {
    margin-top: 8px;

  }

  .card113:hover {
    height: 394px;
}

.card115:hover {
    height: 395px;
    width: 275px;
} */

.content11{
  margin-top: 10px;
}

.content116{
  margin-top: 10px;
}

.content112{
  margin-top: 10px;
}

.build1 p {
    margin-top: 50px;
    font-size: 20px;
    font-weight: 600;
    padding: 33px;
}

.build1 h3 {
    font-weight: 600;
    
    margin-top: -173px;
    width: 306px;
    height: 215px;
    padding: 33px;
}

.titleTwo {
    animation-name: moveLeft;
    animation-duration: 1s;
    margin-left: 38px;
}
    }


    
  
     
