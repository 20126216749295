.link-secondary{
    color: white !important;
}

.container {
    /* margin-top: 423px; */
}
/* 

@media screen and (max-width: 992px) {

    .footetr {
            width: 119%;
        }
} */
